<div
	class="bg"
	[ngClass]="[
		isUnderModal ? 'is-under-modal' : '',
		isBgParent ? 'is-bg-parent' : ''
	]"
>
	<div class="loader">
		<app-spinner></app-spinner>
	</div>
</div>
