import { Component, Inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { LessonsService } from "../services/lessons/lessons.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import {
	FormGroup,
	FormControl,
	FormBuilder,
	Validators
} from "@angular/forms";

@Component({
	selector: "app-cancel-booking",
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		MatDialogModule,
		TranslateModule,
		ReactiveFormsModule
	],
	templateUrl: "./cancel-booking.component.html",
	styleUrl: "./cancel-booking.component.scss"
})
export class CancelBookingComponent {
	public cancelingError: boolean = false;
	textareaValue: string = "";
	textareaForm: FormGroup;

	constructor(
		public dialogRef: MatDialogRef<CancelBookingComponent>,
		private lessonsService: LessonsService,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA)
		public data: { bookingId: string; bookingType: string }
	) {
		this.textareaForm = this.fb.group({
			textarea: ["", Validators.required] // Set an empty initial value
		});
	}

	closeDialog() {
		this.dialogRef.close();
	}

	cancelBooking() {
		this.lessonsService
			.updateBooking(this.data.bookingId, {
				status: "canceled",
				cancelReason: this.textareaForm.value.textarea
			})
			.subscribe({
				next: () => {
					this.dialogRef.close();
					this.lessonsService.updateBookingList$.next();
				},
				error: (err) => {
					this.cancelingError = true;
				}
			});
	}
}
