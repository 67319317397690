import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import {
	catchError,
	distinctUntilChanged,
	map,
	takeUntil
} from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { RECORDING } from "../../constants/connection-constants";
import { AuthenticationService } from "../authentication/authentication.service";
import { RecordingState } from "../../enums/recording-state";
import { RealtimeDatabaseService } from "../ws/rtdb.service";
import { environment } from "@env/environment";

export interface RecordingListItem {
	id: string;
	user_id: string;
	room_id: string;
	student_id: string;
	url: string;
	size: number;
	name: string;
	key: string;
	isDownloadListMenuOpen?: boolean;
	isEditorOpen?: boolean;
}

export interface UpdateRecordingData {
	name: string;
}

@Injectable({ providedIn: "root" })
export class RecordingService {
	public queryParams: { [key: string]: string } = {};
	private httpOptions = {
		withCredentials: true
	};
	public recordingState$: BehaviorSubject<RecordingState> =
		new BehaviorSubject<RecordingState>(null);
	public recordingList$: BehaviorSubject<RecordingListItem[]> =
		new BehaviorSubject<RecordingListItem[]>([]);

	destroyed$ = new Subject<boolean>();

	constructor(
		private http: HttpClient,
		private readonly route: ActivatedRoute,
		private auth: AuthenticationService,
		private webSocketService: RealtimeDatabaseService
	) {}

	public getListByUserId(
		userId: string,
		page: string = "1",
		limit: string = "10"
	): Observable<any> {
		return this.http
			.get(
				`${RECORDING}/list?page=${page}&limit=${limit}&teacherId=${userId}`,
				this.authHttpOptions
			)
			.pipe(
				map((list: any) => list),
				catchError(this.handleError<any>("getList"))
			);
	}

	public getListByRoomTitle(
		roomTitle: string,
		page: string = "1",
		limit: string = "10"
	): Observable<any> {
		return this.http
			.get(
				`${RECORDING}/list?page=${page}&limit=${limit}&roomTitle=${roomTitle}`,
				this.authHttpOptions
			)
			.pipe(
				map((list: any) => list),
				catchError(this.handleError<any>("getList"))
			);
	}

	public start(roomName: string, roomUrl: string): Observable<any> {
		const recordingStartRequestBody = { hostRecording: roomUrl };

		return this.http
			.post(
				`${RECORDING}/${roomName}/start`,
				recordingStartRequestBody,
				this.authHttpOptions
			)
			.pipe(
				map((res: any) => res),
				catchError(this.handleError<any>("start"))
			);
	}

	public confirm(roomName: string): Observable<any> {
		return this.http.post(`${RECORDING}/${roomName}/confirm`, null, {
			headers: new HttpHeaders({
				"bot-key": environment.botkey,
				"bot-type": "webapp"
			})
		});
	}

	public stop(roomName: string): Observable<any> {
		return this.http
			.post(`${RECORDING}/${roomName}/stop`, {}, this.authHttpOptions)
			.pipe(
				map((res: any) => res),
				catchError(this.handleError<any>("stop"))
			);
	}

	private handleError<T>(operation = "operation", result?: T) {
		return (error): Observable<T> => throwError({ message: error });
	}

	private get authHttpOptions() {
		return {
			...this.httpOptions,
			headers: new HttpHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: this.auth.accessToken || "",
				"bot-key": environment.botkey,
				"bot-type": "webapp"
			})
		};
	}

	watchRecordingState(roomName: string) {
		this.webSocketService
			.subscribe<RecordingState>(`rooms/${roomName}/stateRecording`)
			.pipe(
				takeUntil(this.destroyed$),
				distinctUntilChanged(),
				map((x) => {
					return x || RecordingState.stop;
				}) // can be null in RTDB, means stopped
			)
			.subscribe(
				(recordingState: RecordingState) => {
					console.log(recordingState, "recordingState");
					this.recordingState$.next(recordingState);
				},
				(error) => console.log(error, "----err")
			);
	}

	downloadBlob(url: string) {
		const httpOptions = {
			...this.httpOptions,
			headers: new HttpHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: this.auth.accessToken || "",
				observe: "response",
				responseType: "blob" as "json"
			})
		};
		return this.http.get<Blob>(url, httpOptions);
	}

	deleteRecording(recordingId: string): Observable<any> {
		return this.http.delete(
			`${RECORDING}/${recordingId}`,
			this.authHttpOptions
		);
	}

	checkRecordingStatus(roomId: string): Observable<any> {
		return this.http.get(`${RECORDING}/${roomId}/status`);
	}

	updateRecording(recordingId: string, data: UpdateRecordingData) {
		return this.http.put(
			`${RECORDING}/${recordingId}`,
			data,
			this.authHttpOptions
		);
	}
}
