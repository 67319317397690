<!-- <div class="main-container" [ngStyle]="{'padding-top': isUserNameEntered && isSafariOnIos() ? '35px' : 'auto'}"> -->
<div class="main-container" [class.desktop]="isDesktop()" [class.pre-call]="isUserNameEntered"
  [ngStyle]="{'padding-top': 'auto'}">
  <div *ngIf="!(customThemeService.logoURL$ | async)" class="pl-10 md:mt-4 logo-outer"><a><img alt="Sirius" class="logo"
        src="assets/icons/sirius/Sirius.png"></a></div>
  <div *ngIf="(customThemeService.logoURL$ | async)" class="pl-10 md:mt-4 logo-outer"><a><img alt="Sirius" class="logo"
        src="{{(customThemeService.logoURL$ | async)}}"></a></div>

  <div class="room-link">{{roomLink}}</div>
  <div class="new-content" [ngClass]="{'settings-wrapper' : isUserNameEntered || useDefaultName}">
    <div id="enterCallForm"
      [ngStyle]="{'width' : !isUserNameEntered && !useDefaultName && win.innerWidth <= 767.9 ? win.innerWidth - 60 +'px' : '100%' }">
      <app-pre-call-info *ngIf="!isUserNameEntered && !useDefaultName; else preCallSettings" [displaySettings]="displaySettings"
        (doInputUserPassword)="doInputUserPassword.emit()" (displayPermission)="checkPermission($event)">
      </app-pre-call-info>
      <ng-template #preCallSettings>
        <app-request-permission *ngIf="displayPermission" (displayPermission)="checkPermission($event)">
        </app-request-permission>
        <app-pre-call-settings *ngIf="!displayPermission" [videoSettingsCanBeClosed]="videoSettingsCanBeClosed"
          (displayPermission)="checkPermission($event)" [displaySettings]="displaySettings"
          (entryAllowed)="allowedEntry($event)" (isInitMedia)="isMediaInit($event)">
        </app-pre-call-settings>
      </ng-template>
    </div>
  </div>
</div>