import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit,
	ViewEncapsulation,
	ViewChild,
	NgZone
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MediaDevicesService } from "src/app/lib-media-devices/services/media-devices.service";
import { PlatformDetectorService } from "../../../services/platform-detector/platform-detector.service";
import {
	MediaProperies,
	MediaProperiesUtil,
	ParamNumber,
	ParamNumberUtil,
	Resolution,
	SettingPopupData
} from "./setting-popup.interface";
import {
	DEBUG_VALUE_21,
	LocationUtil,
	PRM_DEBUG
} from "src/app/lib-core/utils/location.util";
import { EasyRtcService } from "src/app/lib-rtc/services/easy-rtc.service";
import { LanguageService } from "../../../services/languages/language.service";
import { ProfileService } from "src/app/profile/profile.service";
import { Profile } from "src/app/types/profile.types";
import { PersonalRoomVO } from "src/app/services/data/PersonalRoomVO";
import { ClipboardService } from "src/app/services/clipboard.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { RtcStreamViewModel } from "../../../components/call-room/service/viev-model/rtc-stream-view-model";
import { catchError, switchMap, debounceTime } from "rxjs/operators";
import { of, throwError, fromEvent, Subscription } from "rxjs";
import { logger } from "../../../lib-core/logger";
import { EventSocketService } from "../../../services/ws/event-socket.service";
import { SOCKET_EVENT } from "../../../services/ws/event-socket.service";
import { PlatformService } from "../../../platform/platform.service";
import { WebSocketService } from "../../../services/ws/web-socket.service";
import { VideoService } from "../../../video/video.service";
import {
	SpeakerTime,
	SpeakerTimeDetectorService
} from "../../../services/speaker-time-detector.service";
import { UserVO } from "../../../services/data/UserVO";
import { MatSelect } from "@angular/material/select";
import { MidiService } from "../../../services/midi/midi.service";
import { BG_COLORS } from "src/app/components/call-room/data/themes";
import { getSubDomain } from "src/app/helpers";
import { UsersService } from "../../../services/users.service";
import { roomKeyFromUrl } from "src/app/utils/url.util";
import { WatchrtcService } from "src/app/services/watchrtc.service";
import { AudioContextService } from "src/app/services/audio-context/audio-context.service";

declare const navigator: any;
const SAMPLE_RATE: ParamNumber[] = [
	{ name: "video-settings.sample-size.low", param: 64000, default: true },
	{ name: "video-settings.sample-size.medium", param: 128000 },
	{ name: "video-settings.sample-size.high", param: 192000 }
];
const VIDEO_RESOLUTIONS = [
	{
		id: 0,
		name: "video-settings.video-quality.mobile-low",
		width: 480,
		height: 270
	},
	{
		id: 1,
		name: "video-settings.video-quality.medium",
		width: 480,
		height: 360,
		default: true
	},
	{
		id: 2,
		name: "video-settings.video-quality.low",
		width: 640,
		height: 360
	},

	// {
	// 	id: 3,
	// 	name: "video-settings.video-quality.medium",
	// 	width: 640,
	// 	height: 480,
	// },
	{
		id: 3,
		name: "video-settings.video-quality.high",
		width: 1280,
		height: 720
	},
	{
		id: 4,
		name: "HD 4:3 (960p)",
		width: 1280,
		height: 960
	}
];
const VIDEO_FRAME_RATES: ParamNumber[] = [
	{ name: "video-settings.frame-rate.low", param: 12 },
	{ name: "video-settings.frame-rate.medium", param: 18, default: true },
	{ name: "video-settings.frame-rate.high", param: 24 },
	{ name: "video-settings.frame-rate.extra", param: 30 }
];

@Component({
	selector: "app-setting-popup",
	templateUrl: "./setting-popup.component.html",
	styleUrls: ["./setting-popup.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default
})
export class SettingPopupComponent implements OnInit {
	// Setting: audio
	public audioSampleRates: ParamNumber[] = SAMPLE_RATE;
	// Setting: video
	public videoResolutions: Resolution[] = VIDEO_RESOLUTIONS;
	public videoFrameRates: ParamNumber[] = VIDEO_FRAME_RATES;
	public audioInputDevices: MediaDeviceInfo[] = [];
	public audioOutputDevices: MediaDeviceInfo[] = [];
	public videoInputDevices: MediaDeviceInfo[] = [];
	public midiInputDevices: any = [];
	public formGroup: UntypedFormGroup;
	public mediaStream: MediaStream;
	public isProgress = false;
	public bgColors = BG_COLORS;
	public currentBgColor = localStorage.getItem("bgColor") || "#162329";
	public roomData: PersonalRoomVO;
	public hasAudioOrVideoChange: boolean = false;
	public deviceChange$;
	public isScheduledLesson = false;
	public support = {
		// Audio
		isSampleRate: false,
		isEchoCancellation: false,
		isNoiseSuppression: false,
		isAutoGainControl: false,
		isStereoAudio: false,
		// Video
		isFrameRate: false
	};
	public audioControls = {
		inputDeviceId: new UntypedFormControl(null, []), // audioInputDevices
		outputDeviceId: new UntypedFormControl(null, []), // audioOutputDevices
		sampleRate: new UntypedFormControl(null, []), // audioSampleRates
		echoCancellation: new UntypedFormControl(true, []),
		noiseSuppression: new UntypedFormControl(false, []),
		autoGainControl: new UntypedFormControl(false, []),
		isStereoAudio: new UntypedFormControl(false, [])
	};

	public videoControls = {
		inputDeviceId: new UntypedFormControl(null, []), // videoInputDevices
		resolution: new UntypedFormControl(null, []), // videoResolutions
		frameRate: new UntypedFormControl(null, []), // videoFrameRates
		isReflection: new UntypedFormControl(null, [])
	};

	public secondCamera = {
		secondCamera: new UntypedFormControl(null, [])
	};

	private isDebug = false;
	public isBrowserSafari = false;
	private isBrowserFirefox = false;
	private isMobileOrTablet = false;
	private isUsingOnlyOneMediaStream = false;
	private currentMediaProperies: MediaProperies | null = null;
	public tabIndex = 0;
	public isSafari: boolean;
	public speakerTimeInfo: SpeakerTime = {};
	public localMicInfo: SpeakerTime = {};

	private microphone: MediaStreamAudioSourceNode;
	private analyser: AnalyserNode;
	private javascriptNode: ScriptProcessorNode;
	private audioContext: AudioContext;
	currentVolume: number;
	private deviceChangeSubscription: Subscription;
	private selfStream: MediaStream;
	get userList(): UserVO[] {
		return this.speakerTimeService.userList;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: SettingPopupData,
		private matDialogRef: MatDialogRef<MediaStreamConstraints>,
		private changeDetectorRef: ChangeDetectorRef,
		private mediaDevicesService: MediaDevicesService,
		private easyRtcService: EasyRtcService,
		public auth: AuthenticationService,
		private profileService: ProfileService,
		public clipboardService: ClipboardService,
		public languageService: LanguageService,
		public midiService: MidiService,
		private platformDetectorService: PlatformDetectorService,
		private rtcStreamViewModel: RtcStreamViewModel,
		public platformService: PlatformService,
		private webSocketService: WebSocketService,
		private videService: VideoService,
		private speakerTimeService: SpeakerTimeDetectorService,
		private usersService: UsersService,
		private watchrtcService: WatchrtcService,
		private audioContextService: AudioContextService,
		private ngZone: NgZone
	) {
		if (data.params.switchToRoomTab) {
			this.tabIndex = 2; // The Room tab (with Link)
		}
		this.isDebug =
			LocationUtil.findGetParameter(PRM_DEBUG) === DEBUG_VALUE_21;
		this.formGroup = new UntypedFormGroup({
			audio: new UntypedFormGroup(this.audioControls),
			video: new UntypedFormGroup(this.videoControls),
			secondCamera: new UntypedFormGroup(this.secondCamera),
			general: new UntypedFormGroup({
				langId: new UntypedFormControl(
					this.languageService.selectedLangId ||
						this.languageService.languages[0]._id,
					[]
				)
			}),
			room: new UntypedFormGroup({
				bgColor: new UntypedFormControl(this.currentBgColor, [])
			})
		});
		const mediaTrackSupported: MediaTrackSupportedConstraints =
			this.mediaDevicesService.getSupportedConstraints();
		const supportedConstraints = Object.keys(mediaTrackSupported);
		// Audio
		this.support.isSampleRate = supportedConstraints.includes("sampleRate");
		this.support.isEchoCancellation =
			supportedConstraints.includes("echoCancellation");
		this.support.isNoiseSuppression =
			supportedConstraints.includes("noiseSuppression");
		this.support.isAutoGainControl =
			supportedConstraints.includes("autoGainControl");
		// Video
		this.support.isFrameRate = supportedConstraints.includes("frameRate");
	}

	@ViewChild("outputDeviceSelect") outputDeviceSelect: MatSelect;

	async ngOnInit() {
		this.isSafari = this.platformDetectorService.isSafari();
		this.isBrowserSafari = this.platformDetectorService.isBrowserSafari();
		this.isBrowserFirefox = this.platformDetectorService.isFirefox();
		this.isMobileOrTablet =
			this.platformDetectorService.isMobile() ||
			this.platformDetectorService.isTablet() ||
			this.platformDetectorService.isIPad();
		this.isUsingOnlyOneMediaStream =
			/*this.isBrowserFirefox ||*/ this.isMobileOrTablet;
		this.setDefaultVideo();
		this.isProgress = true;
		this.markForCheck();

		if (!getSubDomain()) {
			this.watchrtcService.trackEvent("settings_popup_opened", {
				settingsPopupOpened: true
			});
		}

		this.isScheduledLesson = !!roomKeyFromUrl();

		//  Here we get the current devices via enumerateDevices
		const deviceIds: MediaDeviceInfo[] =
			await this.mediaDevicesService.enumerateDevices(true);

		//  Here we set the current values received from enumerateDevices
		this.audioInputDevices =
			this.mediaDevicesService.getDeviceAudioInput(deviceIds);
		this.audioOutputDevices =
			this.mediaDevicesService.getDeviceAudioOutput(deviceIds);
		this.videoInputDevices =
			this.mediaDevicesService.getDeviceVideoInput(deviceIds);

		//  Here we read the current values from storage (The later rendered form values are based on settings saved in local storage)
		const mediaProperies = (this.currentMediaProperies =
			MediaProperiesUtil.readMediaProperiesFromStorage());
		const defaultVideoSettings = this.getDefaultVideo();
		if (defaultVideoSettings.width) {
			mediaProperies.videoWidth = defaultVideoSettings.width;
		}
		if (!defaultVideoSettings.height) {
			mediaProperies.videoHeight = defaultVideoSettings.height;
		}

		// Audio / Here we set the media Properties which were fetched via MediaProperiesUtil.readMediaProperiesFromStorage (with readAudioInputDeviceToStorage, readAudioOutputDeviceToStorage)
		this.prepareAudio(mediaProperies);

		// Video Settings
		this.prepareVideo(mediaProperies);

		// Second Camera

		this.secondCamera.secondCamera.setValue(null, { emitEvent: false });

		this.videoControls.isReflection.setValue(
			!!mediaProperies.videoIsReflection,
			{ emitEvent: false }
		);
		this.isProgress = false;
		this.loadRoomInfo();
		this.getProfileInfo();
		const initialVideoValue = this.formGroup.controls.video;
		const initialAudioValue = this.formGroup.controls.audio;

		// Sirius-1330 / David
		let prevDevices: MediaDeviceInfo[] = [];
		prevDevices = await navigator.mediaDevices.enumerateDevices();
		this.deviceChange$ = fromEvent(
			window.navigator.mediaDevices,
			"devicechange"
		).pipe(debounceTime(2000));
		this.deviceChangeSubscription = this.deviceChange$.subscribe(
			async (event: Event) => {
				navigator.mediaDevices.enumerateDevices().then((devices) => {
					// check if device list has new devices
					const newDevices = devices.filter(
						(device) =>
							!prevDevices.some(
								(prevDevice) =>
									prevDevice.deviceId === device.deviceId
							)
					);
					const newOutputDevices = newDevices.filter(
						(device) => device.kind === "audiooutput"
					);
					const newInputDevices = newDevices.filter(
						(device) => device.kind === "audioinput"
					);
					// If new device list is update assign new value to prevDevices for next check
					prevDevices = devices;
					if (newOutputDevices.length > 0) {
						this.audioOutputDevices = devices.filter(
							(device) => device.kind === "audiooutput"
						);
						const newAudioDevice = newDevices.filter(
							(device) => device.kind === "audiooutput"
						);
						this.formGroup.controls.audio
							.get("outputDeviceId")
							.setValue(newAudioDevice[0].deviceId);
					} else {
						if (this.audioOutputDevices.length > 0) {
							// Safari fix
							this.audioOutputDevices = devices.filter(
								(device) => device.kind === "audiooutput"
							);
							this.formGroup.controls.audio
								.get("outputDeviceId")
								.setValue(this.audioOutputDevices[0].deviceId);
						}
					}
					if (newInputDevices.length > 0) {
						this.audioInputDevices = devices.filter(
							(device) => device.kind === "audioinput"
						);
						const newAudioInputDevice = newDevices.filter(
							(device) => device.kind === "audioinput"
						);
						this.formGroup.controls.audio
							.get("inputDeviceId")
							.setValue(newAudioInputDevice[0].deviceId);
					} else {
						this.audioInputDevices = devices.filter(
							(device) => device.kind === "audioinput"
						);
						this.formGroup.controls.audio
							.get("inputDeviceId")
							.setValue(this.audioInputDevices[0].deviceId);
					}
				});
			}
		);

		// David End

		this.formGroup.controls?.secondCamera?.valueChanges.subscribe(
			async (value) => {
				if (value.secondCamera == "none") {
					this.removeSecondCamera("secondStream");
				} else {
					this.removeSecondCamera("secondStream");
					if (value !== null || value !== "none") {
						this.easyRtcService.setVideoSource(value.secondCamera);

						const stream =
							await this.easyRtcService.initMediaSourceTwo();

						this.usersService.selfUser.mediaStreamSecondVideo =
							stream;

						const self = this.usersService
							.getConnectedUsers()
							.filter((obj) => obj.self === true);

						const externalConnectedUsers = this.usersService
							.getConnectedUsersWithRecordingBot()
							.filter((obj) => obj.self === false);

						const array =
							EasyRtcService.getRoomOccupantsAsArray("default");

						const selfId = EasyRtcService.myEasyrtcid();

						self[0].secondStreamActive = true;

						const filteredArray = array.filter(
							(item) => item !== selfId
						);

						if (externalConnectedUsers.length > 0) {
							for (let i = 0; i < filteredArray.length; i++) {
								EasyRtcService.addStreamToCall(
									filteredArray[i],
									"secondStream",
									(user, streamName) => {
										console.log(user, streamName);
									}
								);
							}
						}
					} else {
						this.removeSecondCamera("secondStream");
					}
				}
			}
		);

		this.formGroup.controls?.video?.valueChanges.subscribe(() => {
			this.registerSettingsChange();
			this.hasAudioOrVideoChange = Object.keys(initialVideoValue).some(
				(key) => this.formGroup.value[key] !== initialVideoValue[key]
			);
			this.watchrtcService.trackEvent("video_settings_changed", {
				settingsChange: true
			});
		});

		// If the audio settings got changed then start the init process here
		this.formGroup.controls?.audio?.valueChanges
			.pipe(debounceTime(1000))
			.subscribe(() => {
				this.registerSettingsChange();
				this.hasAudioOrVideoChange = Object.keys(
					initialAudioValue
				).some(
					(key) =>
						this.formGroup.value[key] !== initialAudioValue[key]
				);
				this.watchrtcService.trackEvent("audio_settings_changed", {
					settingsChange: true
				});
			});
		
		await this.changeAudioMeterSource();


		this.markForCheck();
		this.speakerTimeService.speakerTimeData$.subscribe(
			(speakerTimeInfo) => (this.speakerTimeInfo = speakerTimeInfo)
		);
		this.speakerTimeService.localMicTimeData$.subscribe(
			(localMicInfo) => (this.localMicInfo = localMicInfo)
		);

		// Midi
		if (navigator.requestMIDIAccess) {
			this.midiInputDevices =
				await this.midiService.getMidiInputDevices();
			// console.log(this.midiInputDevices.size);

			this.midiService.midiInputAccess$.subscribe(
				(access: any) => {
					const midiAccess: any = access as any;
					// OnStateChange-Ereignishandler zuweisen
					midiAccess.onstatechange = async (event) => {
						this.midiInputDevices =
							await this.midiService.getMidiInputDevices();
						// console.log(this.midiInputDevices.size);
					};
				},
				(error) => {
					console.error(
						"Fehler beim Zugriff auf MIDI-Geräte:",
						error
					);
				}
			);
		}

	}

	removeSecondCamera(streamName) {
		let array = EasyRtcService.getRoomOccupantsAsArray("default");
		const stringToRemove = EasyRtcService.myEasyrtcid();
		const filteredArray = array.filter((item) => item !== stringToRemove);
		EasyRtcService.closeLocalMediaStream(streamName);
		filteredArray.forEach((userId) => {
			EasyRtcService.removeStreamFromCall(userId, streamName);
		});
		this.usersService.selfUser.mediaStreamSecondVideo = null;
		this.usersService.selfUser.secondStreamActive = false;
	}

	ngOnDestroy() {
		if (this.deviceChangeSubscription) {
			this.deviceChangeSubscription.unsubscribe();
		}
		this.selfStream = null;
		this.analyser.disconnect();
		this.javascriptNode.disconnect();
		this.microphone.disconnect();
	}

	midiAvailable() {
		return this.midiInputDevices.size > 0;
	}

	getProfileInfo() {
		this.profileService.get().subscribe((profile: Profile) => {
			if (!profile) {
				return;
			}
			const selectedLanguage = this.languageService.languages.find(
				(lang) => lang._id === profile.languageId
			);
			if (selectedLanguage) {
				this.languageService.selectedLangId = selectedLanguage._id;
				this.formGroup.patchValue({
					general: { langId: selectedLanguage._id }
				});
			}
		});
	}

	onChangeBgColor(i: number) {
		this.currentBgColor = this.bgColors[i];
		this.formGroup.patchValue(
			{ room: { bgColor: this.currentBgColor } },
			{ emitEvent: true }
		);
		this.formGroup.markAsDirty();
		const mediaProperties = this.createMediaProperies();
		MediaProperiesUtil.writeMediaProperiesToStorage(mediaProperties);
		this.doSaveBackgroundColor(mediaProperties.bgColor);
	}

	loadRoomInfo() {
		this.roomData = JSON.parse(localStorage.getItem("personalRoom"));
	}

	copyLink() {
		this.clipboardService.copyLink(
			this.roomData.link,
			this.roomData.password
		);
	}

	setDefaultVideo(): void {
		const uAgentData = this.mediaDevicesService.getUserAgentData();
		if (
			(!uAgentData && this.platformDetectorService.isMobile()) ||
			(uAgentData &&
				uAgentData.mobile &&
				this.platformDetectorService.isMobile())
		) {
			this.videoResolutions = this.videoResolutions.map((res) => {
				res.default = res.id === 0;
				return res;
			});
		}
	}

	getDefaultVideo(): Resolution {
		if (this.currentMediaProperies.videoResolution) {
			return this.videoResolutions.find(
				(res) =>
					res.id === this.currentMediaProperies.videoResolution.id
			);
		}
		return this.videoResolutions.find((res) => res.default);
	}
	// ** Public API **

	public close(): void {
		if (this.isUsingOnlyOneMediaStream) {
			this.closeModal(this.currentMediaProperies);
		} else {
			this.closeModal(null);
		}
	}

	public async testAudio() {
		const audioEnter = new Audio("/assets/EnterSound.mp3");
		await (audioEnter as any).setSinkId(
			this.audioControls.outputDeviceId.value
		);
		await audioEnter.play();
	}

	public doChangeReflection(videoIsReflection: boolean): void {
		this.videService.isReflection$.next(videoIsReflection);
		this.markForCheck();
	}

	public doChangeEchoCancellation(): void {
		this.changeAudioEchoCancellation();
	}

	public submit(): void {
		if (this.formGroup.valid) {
			this.closeModal(this.createMediaProperies());
		}
	}

	// ** Private API **

	private changeAudioEchoCancellation(): void {
		let isStereoAudioValue = this.audioControls.isStereoAudio.value;
		const audioEchoCancellation = this.audioControls.echoCancellation.value;
		if (
			this.support.isStereoAudio &&
			isStereoAudioValue &&
			!!audioEchoCancellation
		) {
			isStereoAudioValue = false;
		}
		if (this.audioControls.isStereoAudio.value !== isStereoAudioValue) {
			this.audioControls.isStereoAudio.setValue(isStereoAudioValue);
			MediaProperiesUtil.writeAudioChannelCountToStorage(
				isStereoAudioValue ? 2 : 1
			);
		}
	}

	public doChangeLanguage() {
		const mediaProperties = this.createMediaProperies();
		MediaProperiesUtil.writeMediaProperiesToStorage(mediaProperties);
		this.doSaveLanguage(mediaProperties.langId);
		this.markForCheck();
	}

	private prepareAudio(mediaProperiesPrm: Partial<MediaProperies>): void {
		const mediaProperies: Partial<MediaProperies> = mediaProperiesPrm || {};

		if (this.audioInputDevices.length > 0) {
			//  Here we set the form value to the audio input device
			const audioInpDevInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.audioInputDevices,
					mediaProperies.audioInpDev
				);
			this.audioControls.inputDeviceId.setValue(
				!!audioInpDevInfo ? audioInpDevInfo.deviceId : null,
				{ emitEvent: false }
			);
		} else {
			//  Here we disable the controls because no input device was found
			this.audioControls.inputDeviceId.disable({ emitEvent: false });
		}

		if (this.audioOutputDevices.length > 0) {
			//  Here we set the form value to the audio output device
			const audioOutDevInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.audioOutputDevices,
					mediaProperies.audioOutDev
				);
			this.audioControls.outputDeviceId.setValue(
				audioOutDevInfo.deviceId,
				{ emitEvent: false }
			);
		} else {
			//  Here we disable the controls because no output device was found
			this.audioControls.outputDeviceId.disable({ emitEvent: false });
		}

		if (this.support.isSampleRate) {
			const sampleRateDefault: ParamNumber = ParamNumberUtil.findDefault(
				this.audioSampleRates
			);
			const sampleRate: number = mediaProperiesPrm.audioSampleRate;
			const sampleRateValue = ParamNumberUtil.findParam(
				this.audioSampleRates,
				sampleRate,
				sampleRateDefault
			);
			this.audioControls.sampleRate.setValue(
				!!sampleRateValue ? sampleRateValue.param : null,
				{ emitEvent: false }
			);
		}
		if (this.support.isEchoCancellation) {
			this.audioControls.echoCancellation.setValue(
				mediaProperies.audioEchoCancellation,
				{ emitEvent: false }
			);
		}
		if (this.support.isNoiseSuppression) {
			this.audioControls.noiseSuppression.setValue(
				mediaProperies.audioNoiseSuppression,
				{ emitEvent: false }
			);
		}
		if (this.support.isAutoGainControl) {
			this.audioControls.autoGainControl.setValue(
				mediaProperies.audioAutoGainControl,
				{ emitEvent: false }
			);
		}
		if (this.support.isStereoAudio) {
			this.audioControls.isStereoAudio.setValue(
				mediaProperies.audioChannelCount > 1 ? true : false,
				{ emitEvent: false }
			);
		}
	}

	private prepareVideo(mediaProperiesPrm: Partial<MediaProperies>): void {
		const mediaProperies: Partial<MediaProperies> = mediaProperiesPrm || {};

		// videoDeviceInfo
		if (this.videoInputDevices.length > 0) {
			const videoDeviceInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.videoInputDevices,
					mediaProperies.videoInpDev
				);
			this.videoControls.inputDeviceId.setValue(
				videoDeviceInfo.deviceId,
				{ emitEvent: false }
			);
		} else {
			this.videoControls.inputDeviceId.disable({ emitEvent: false });
		}

		// videoFrameRate
		if (this.support.isFrameRate) {
			const frameRateDefault: ParamNumber = ParamNumberUtil.findDefault(
				this.videoFrameRates
			);
			const frameRate: number = mediaProperiesPrm.videoFrameRate;
			const frameRateValue = ParamNumberUtil.findParam(
				this.videoFrameRates,
				frameRate,
				frameRateDefault
			);
			this.videoControls.frameRate.setValue(
				!!frameRateValue ? frameRateValue.param : null,
				{ emitEvent: false }
			);
		}

		// resolution
		const resolution: Resolution = this.getDefaultVideo();
		this.videoControls.resolution.setValue(
			!!resolution ? resolution : null,
			{ emitEvent: false }
		);
	}

	// private prepareSecondCamera(
	// 	mediaProperiesPrm: Partial<MediaProperies>
	// ): void {
	// 	if (mediaProperiesPrm.secondCameraInputDevice !== null) {
	// 		this.secondCamera.secondCamera.setValue(
	// 			mediaProperiesPrm.secondCameraInputDevice,
	// 			{ emitEvent: false }
	// 		);
	// 	}
	// }

	private markForCheck(): void {
		if (this.isBrowserSafari) {
			this.changeDetectorRef.detectChanges();
		} else {
			this.changeDetectorRef.markForCheck();
		}
	}

	private async closeModal(mediaProperties: MediaProperies): Promise<void> {
		let result: MediaProperies | null = null;
		this.isProgress = true;
		this.markForCheck();

		if (!!mediaProperties) {
			MediaProperiesUtil.writeMediaProperiesToStorage(mediaProperties);
			result = mediaProperties;
		} else if (!!this.currentMediaProperies) {
			result = !this.isBrowserSafari ? this.currentMediaProperies : null;
		}
		const hasAudioOrVideoChange = this.hasAudioOrVideoChange;

		if (!!result) {
			this.isBrowserSafari
				? this.matDialogRef.close({ result, hasAudioOrVideoChange })
				: this.matDialogRef.close(result);
		} else {
			this.matDialogRef.close();
		}
	}

	private createMediaProperies(): MediaProperies {
		const resolution: Resolution = this.videoControls.resolution
			.value as Resolution;
		const audioEchoCancellation = this.audioControls.echoCancellation.value;
		const formValue = this.formGroup.getRawValue();

		return {
			// Audio
			audioInpDev: this.audioControls.inputDeviceId.value,
			audioOutDev: this.audioControls.outputDeviceId.value,
			audioSampleRate: this.audioControls.sampleRate.value,
			audioEchoCancellation,
			audioNoiseSuppression: this.audioControls.noiseSuppression.value,
			audioAutoGainControl: this.audioControls.autoGainControl.value,
			audioChannelCount:
				!audioEchoCancellation && this.audioControls.isStereoAudio.value
					? 2
					: 1,
			// Video
			videoInpDev: this.videoControls.inputDeviceId.value,
			videoWidth: !!resolution ? resolution.width : undefined,
			videoHeight: !!resolution ? resolution.height : undefined,
			videoFrameRate: this.videoControls.frameRate.value,
			videoIsReflection: this.videoControls.isReflection.value,
			// Second Camera
			secondCameraInputDevice: this.secondCamera.secondCamera.value,

			// langulage
			langId: formValue.general.langId,
			bgColor: formValue.room.bgColor,
			videoResolution: resolution
		};
	}

	// ToDo: Discuss if we bring the functionality into mediaDevicesService

	async registerSettingsChange() {
		const mediaProperties = this.createMediaProperies();
		MediaProperiesUtil.writeMediaProperiesToStorage(mediaProperties);
		const mediaStream = EasyRtcService.getLocalStream();
		if (!!mediaStream) {
			await this.easyRtcService.stopMediaStreamTrack(
				mediaStream.getTracks()
			);
		}

		this.currentMediaProperies = mediaProperties;
		const constraints =
			MediaProperiesUtil.createConstraints(mediaProperties);
		await this.rtcStreamViewModel.changeSelectedStream(constraints);

		this.changeAudioMeterSource();
	}

	async registerAudioSettingsChange() {
		const mediaProperties =
			MediaProperiesUtil.readMediaProperiesFromStorage();
		const mediaStream = EasyRtcService.getLocalStream();
		if (!!mediaStream) {
			await this.easyRtcService.stopMediaStreamTrack(
				mediaStream.getTracks()
			);
		}
		this.currentMediaProperies = mediaProperties;
		const constraints =
			MediaProperiesUtil.createConstraints(mediaProperties);
		await this.rtcStreamViewModel.changeSelectedStream(constraints);
	}

	doSaveLanguage(langId: string) {
		const data = { language: langId };
		const selectedLanguage = this.languageService.languages.find(
			(lang) => lang._id === data.language
		);
		if (selectedLanguage) {
			const selectedLanguageIso = selectedLanguage.iso;
			this.languageService.change(selectedLanguageIso);
		}
		this.profileService.save(data).subscribe();
	}

	doSaveBackgroundColor(bgColor: string) {
		this.platformService
			.getPersonalRoom$()
			.pipe(
				catchError((err) => of(logger.log(err))),
				switchMap((data: any[]) => {
					if (data && data.length > 0) {
						const name = data[0].target.split("/").pop();
						return this.platformService
							.updatePersonalRoom(name, bgColor)
							.pipe(
								catchError(() => {
									return throwError(
										"failed to update personal room!"
									);
								})
							);
					} else {
						return of(null);
					}
				})
			)
			.subscribe({
				next: () => {
					localStorage.setItem("bgColor", bgColor);
					this.webSocketService.dispatch(SOCKET_EVENT.EXCHANGE, {
						data: { style: bgColor }
					});
				},
				error: (err) => {
					throwError(err);
				}
			});
	}

	private initAudioAnalyser(stream: MediaStream): void {
		if (!this.audioContext) {
			this.audioContext = this.audioContextService.getAudioContext();
		}
		// Disconnect existing nodes if they exist
		if (this.microphone) {
			this.microphone.disconnect();
		}
		if (this.analyser) {
			this.analyser.disconnect();
		}
		if (this.javascriptNode) {
			this.javascriptNode.disconnect();
		}

		// Create new media stream source and analyser nodes
		this.microphone = this.audioContext.createMediaStreamSource(stream);
		this.analyser = this.audioContext.createAnalyser();
		this.javascriptNode = this.audioContext.createScriptProcessor(
			2048,
			1,
			1
		);

		// Configure the analyser node
		this.analyser.smoothingTimeConstant = 0.8;
		this.analyser.fftSize = 1024;

		// Reconnect the nodes with the new stream
		this.microphone.connect(this.analyser);
		this.analyser.connect(this.javascriptNode);
		this.javascriptNode.connect(this.audioContext.destination);

		// Listen to audioprocess events
		this.javascriptNode.onaudioprocess = () => {
			const array = new Uint8Array(this.analyser.frequencyBinCount);
			this.analyser.getByteFrequencyData(array);
			const values = array.reduce((sum, current) => {
				return sum + current;
			}, 0);

			this.ngZone.run(() => {
				this.currentVolume = values / array.length;
			});
		};
	}

	private async changeAudioMeterSource(): Promise<void> {
		this.selfStream = await navigator.mediaDevices.getUserMedia({
			audio: { deviceId: this.formGroup.controls.audio.value.inputDeviceId }
		});
		this.initAudioAnalyser(this.selfStream);
	}
}
