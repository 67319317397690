import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BOOKING_FEEDBACK } from "../../constants/connection-constants";

import { API_URL } from "src/app/lib-core/constants/constants";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { SessionStorageUtil } from "src/app/lib-core/utils/session-storage.util";
import { QualityRatingStorageUtil } from "../utils/quality-rating-storage.util";

export const URL_ANALITICS = API_URL + "/analytics";

export enum RatingType {
	terrible = "terrible",
	bad = "bad",
	okay = "okay",
	good = "good",
	excellent = "excellent"
}

export class RatingTypeUtil {
	public static create(value: string): RatingType | null {
		let result: RatingType | null = null;
		switch (value) {
			case RatingType.terrible.valueOf():
				result = RatingType.terrible;
				break;
			case RatingType.bad.valueOf():
				result = RatingType.bad;
				break;
			case RatingType.okay.valueOf():
				result = RatingType.okay;
				break;
			case RatingType.good.valueOf():
				result = RatingType.good;
				break;
			case RatingType.excellent.valueOf():
				result = RatingType.excellent;
				break;
		}
		return result;
	}
}

@Injectable({
	providedIn: "root"
})
export class QualityRatingService {
	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService
	) {}

	// ** Public API **

	postQualityRating(
		room: string,
		userId: string,
		rating: RatingType,
		options: string[]
	): Observable<any> {
		const data: any = { room, rating, options };
		if (!!userId) {
			data.userId = userId;
		}
		return this.http.post(URL_ANALITICS, data);
	}

	postLessonFeedback(
		data,
		roomId: string,
		userId: string,
		isTeacher: boolean
	) {
		let url = `${API_URL}/booking/${roomId}/feedback`;
		let requestBody = {};

		for (let key in data) {
			const value = data[key];
			const isEmptyArray = Array.isArray(value) && value.length === 0;

			if (value && !isEmptyArray) {
				requestBody[key] = value;
			}
		}
		if (Object.keys(requestBody).length > 0 && userId) {
			requestBody[isTeacher ? "teacherId" : "studentId"] = userId;
			return this.http.post(url, requestBody);
		} else {
			return new Observable((observer) => {
				observer.next();
				observer.complete();
			});
		}
	}

	getLessonFeedback(bookingId: string) {
		let url = BOOKING_FEEDBACK.replace(":bookingId", bookingId);

		return this.http.get(url, {
			headers: this.authenticationService.headers,
			withCredentials: true
		});
	}

	cleanStorage(): void {
		QualityRatingStorageUtil.writeQualityRatingToSessionStorage("");
		QualityRatingStorageUtil.writeProblemOptionsToSessionStorage("");
		SessionStorageUtil.writeRatingRoomAliasToSessionStorage("");
		SessionStorageUtil.writeRatingIsHiddenHigherPanelToSessionStorage(null);
		QualityRatingStorageUtil.writePanelModeToSessionStorage("");
	}
}
